import { routes } from '../config/api_routes'

export const releaseNotesService = {

    getReleaseNotes: (formData) => window.axios.post(routes.getReleaseNotesApi,formData),
    
    addDashboardReleaseNote: (formData) => window.axios.post(routes.addDashboardReleaseNoteApi,formData),
    
    
    getReleaseNoteById: (formData) => window.axios.post(routes.getReleaseNoteByIdApi,formData),
    
    updateReleaseNoteById: (formData) => window.axios.post(routes.updateReleaseNoteByIdApi,formData),
    
    getClientReleasenote: (formData) => window.axios.post(routes.getClientReleasenoteApi,formData),
}
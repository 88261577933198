<template>
    <router-view></router-view>
</template>

<script>
import config from '../config';
import { orderService } from '../services';
export default {
  name: 'app',
  data(){
    return {
      isConnected: false,
      login_id: null
    }
  },
  watch:{
    "$auth.logging_done":function(){
      if(this.$router.name != 'quick-signup'){
        if(this.$auth.logging_done && this.$auth.user && this.$auth.user.is_white_labeling && this.$auth.user.is_white_labeling == '0'){ 
          this.addEvents();
          this.$auth.hideshowBeamer(true);
        }else{
          if(this.login_id){
            this.sockets.unsubscribe('order_placed_'+this.login_id)
            this.sockets.unsubscribe('order_confirmed_'+this.login_id)
            this.sockets.unsubscribe('order_cancelled_'+this.login_id)
            this.sockets.unsubscribe('order_placed_partner_'+this.login_id)
            this.sockets.unsubscribe('order_confirmed_partner_'+this.login_id)
            this.sockets.unsubscribe('order_cancelled_partner_'+this.login_id)
            this.login_id = null;
          }
        }
      }
    }
  },
  mounted() {
    let language = localStorage.getItem('language') || 'en';
    window.axios.defaults.headers.common["Language"] = language;
    this.trans.setLocale(language);

    if(this.$router.name != 'quick-signup'){
      HTMLMediaElement.autoplay = true;
      if(this.$auth.logging_done){
        this.getOrdersCount();
        setTimeout(() => {
          if(this.$auth.logging_done){
            this.addEvents();
          }
        },5000)
      }
    }
  },
  created(){
      Vue.prototype.base64 = (i)  => btoa(i);
      // let token = localStorage.getItem('token');
      // if(!token && window.location.href.indexOf('login') === -1){
      //     this.$router.push({name:'login'});
      // }else{
      //   axios.defaults.headers.common = {
      //     'Accept': 'application/json',
      //     'Authorization': 'Bearer '+ token
      //   };
      // }
      
      this.$auth.setUser((type)=> {
        if(type) {
          if(this.$route && this.$route.name.includes('login')){
            this.$router.push({ name: type }).catch(()=>{});
          }
        }
      });
  },
  sockets:{
    connect() {
      this.isConnected = true;
    },
    disconnect() {
      this.isConnected = false;

    },
    // message(data){      
    //   this.getOrdersCount();
    //   var myaudio = new Audio(config.appurl+this.$auth.setting.order_notification_sound);
    //   myaudio.play();
    //   myaudio.autoplay;
    // }
  },
  methods:{
    addEvents(){
      this.$auth.getUnreadMessages();
        this.sockets.subscribe('message_notification',
          (data) => {
            this.interval && clearTimeout(this.interval);
            if(this.$auth.getVendorId() !== data.sender_id){
              this.notifyMessage("You have new message from "+data.sender_name);
            }
            if(data.room == this.$auth.room){
              this.$auth.received++;
            }else{
              this.$auth.received++;
              setTimeout(()=> this.$auth.getUnreadMessages(), 500)
            }
          })
        this.sockets.subscribe('messagePass',
          (data) => {
            this.interval && clearTimeout(this.interval);
            if(data.room == this.$auth.room){
              this.$auth.received++;
            } else {
              this.$auth.received++;
            }
          })
      if(this.$auth.user && this.$auth.user.login_type && this.$auth.user.login_type == 'vendor'){
        this.login_id = this.$auth.getVendorId()
        this.sockets.subscribe('order_placed_'+this.$auth.getVendorId(),
          () => {
            this.getOrdersCount();
            var myaudio = new Audio(config.appurl+this.$auth.setting.order_notification_sound);
            myaudio.play();
            myaudio.autoplay;
          })
        this.sockets.subscribe('order_confirmed_'+this.$auth.getVendorId(),
          () => {
            this.getOrdersCount();
          })
        this.sockets.subscribe('order_cancelled_'+this.$auth.getVendorId(),
          () => {
            this.getOrdersCount();
          })
      }else{
        if(!this.$auth.user ){
          return false;
        }
        this.login_id = this.$auth.user.user_id
        this.sockets.subscribe('order_placed_partner_'+this.login_id,
          () => {
            this.getOrdersCount();
            var myaudio = new Audio(config.appurl+this.$auth.setting.order_notification_sound);
            myaudio.play();
            myaudio.autoplay;
          })
        this.sockets.subscribe('order_confirmed_partner_'+this.login_id,
          () => {
            this.getOrdersCount();
          })
        this.sockets.subscribe('order_cancelled_partner_'+this.login_id,
          () => {
            this.getOrdersCount();
          })
      }
    },
    getOrdersCount(){
      orderService
        .getNewOrders()
        .then((response) => {
          this.$auth.updateOrders(response.data.result)
        })
        .catch(function (error) {
        });
    },
    notifyMessage(message){
        if (!("Notification" in window)) {
          // alert("This browser does not support desktop notification");
        }
        else if (Notification.permission === "granted") {
          var notification = new Notification(message);
        }
        else if (Notification.permission !== "denied") {
          Notification.requestPermission().then(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
              var notification = new Notification(message);
            }
          });
        }

    },
  }
}
</script>
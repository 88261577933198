// Banner services rest api calling

import Axios from "axios";
import { routes } from "../config/api_routes";

export const authService = {
    loginUser: (formData) => {
        return Axios.post(routes.login, formData, {
            headers: {
                Accept: "application/json",
            },
        });
    },
    loginAdmin: (formData) => {
        return Axios.post(routes.adminLogin, formData, {
            headers: {
                Accept: "application/json",
            },
        });
    },
    logout: () => {
        return window.axios.post(
            routes.logout,
            {},
            {
                headers: {
                    Accept: "application/json",
                },
            }
        );
    },
    clientLogin: (formData) => {
        return Axios.post(routes.clientlogin, formData, {
            headers: {
                Accept: "application/json",
            },
        });
    },
};

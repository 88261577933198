import { routes } from '../config/api_routes'

export const orderService = {
    getOrders: (filters) => window.axios.post(routes.getOrdersApi, filters),
    getOrder: (id) => window.axios.post(routes.getOrderApi, {order_id: id}),
    getOrdersCount: (filters) => window.axios.post(routes.getOrdersCountApi,filters),
    exportOrders: (filters) => window.axios.post(routes.exportOrdersApi, filters),
    getNewOrders: (filters) => window.axios.post(routes.getNewOrdersApi, filters),
    checkAvailableDrivers: (data)   =>   window.axios.get(routes.checkAvailableDriversApi, {params: data}),
    assignDriver: (data)   =>   window.axios.post(routes.assignDriverApi, data),
    
    
    
    // app apis 
    // ADD APP API BELOW
    changeOrderStatus: (data) => window.axios.post(routes.changeOrderStatusApi, data),
    ownerOrderStatus: (data) => window.axios.post(routes.ownerOrderStatusApi, data),
    cancelOrder: (data) => window.axios.post(routes.cancelOrderApi, data),
    deliverOrder: (data) => window.axios.post(routes.deliverOrderApi, data),
    changeToCodOrder: (data) => window.axios.post(routes.changeToCodOrderApi, data),
    pingOrderDrivers: (data) => window.axios.post(routes.pingDriversApi, data)
}